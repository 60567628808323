<template>
  <v-container fluid class="profile-update-container scroll-container">
    <CategoryTitle :category="category" />

    <br />
    <div class="products">
      <v-row>
        <v-col
          cols="12"
          md="6"
          xl="4"
          v-for="notification in notificationsList"
          :key="notification.messageId"
        >
          <notifications-card
            :key="notification.messageId"
            :notification="notification"
            :show-opened="true"
            :show-read="true"
            @read="markAsRead(notification.messageId)"
          />
        </v-col>
      </v-row>
      <v-pagination
        v-if="pager && pager.totPages > 1 && !$vuetify.breakpoint.xs"
        :value="page"
        :page="pager.selPage"
        :length="pager.totPages ? pager.totPages : 0"
        :totalVisible="3"
        @next.stop.prevent="handlePageFilter"
        @prev="handlePageFilter"
        @input="handlePageFilter"
      ></v-pagination>

      <div class="py-8" v-if="!notificationsList">
        In questa pagina trovi un elenco delle notifiche che ti abbiamo inviato
      </div>
      <div class="py-8" v-if="!notificationsList">Nessuna notifica trovata</div>
    </div>
  </v-container>
</template>

<script>
import CategoryTitle from "@/components/category/CategoryTitle";
import NotificationsCard from "@/components/notification/NotificationsCard.vue";
import category from "~/mixins/category";
import baskoCustomService from "@/service/baskoCustomService";

export default {
  name: "NotificationListPage",
  mixins: [category],
  components: { CategoryTitle, NotificationsCard },
  data() {
    return {
      default_page_size: 24,
      notificationsList: [],
      pager: {},
      page: 1
    };
  },
  computed: {
    showDialog() {
      console.log("showDialog");

      return this.notificationsList.length > 0;
    }
  },
  methods: {
    async fetchHistory(page) {
      console.log("fetchHistory");
      let _this = this;
      let dataRaw = null;

      dataRaw = await baskoCustomService.notificationHistoryList(
        4,
        page,
        _this.default_page_size
      );

      if (dataRaw) {
        _this.notificationsList = dataRaw.values;
        _this.pager = dataRaw.page;
      } else {
        _this.notificationsList = null;
        _this.pager = null;
      }
    },

    handlePageFilter(page) {
      console.log("handlePageFilter");

      var _this = this;
      // console.log(page);
      this.$store.dispatch("category/setFilterPage", page);
      //await di this.fetchProduts
      // this.fetchHistory(this.page);
      this.fetchHistory(page);
      this.page = page;
      //oppure mettere goTo(0) dentro timeout
      setTimeout(function() {
        _this.$vuetify.goTo(0);
      }, 300);
    }
  },
  mounted() {
    this.fetchHistory(this.page);
  }
};
</script>
