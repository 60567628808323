<template>
  <v-card
    class="notification-card h-100 d-flex flex-column justify-space-between"
  >
    <v-card-title style="  padding-bottom: 0px; ">
      <v-col cols="4" md="4" class="px-0 pb-3">
        <div class="field-wrapper">
          <div class="f-label" style="font-size: medium;">
            {{ $t("notification.from") }}
          </div>
          <div class="f-value">{{ notification.fromContact }}</div>
        </div>
      </v-col>

      <v-col cols="4" md="4" class="px-0 pb-3">
        <div class="field-wrapper">
          <div class="f-label" style="font-size: medium;">
            {{ $t("notification.to") }}
          </div>
          <div class="f-value">{{ notification.contacts }}</div>
        </div>
      </v-col>

      <v-col cols="4" md="4" class="px-0 pb-3">
        <div class="field-wrapper">
          <div class="f-label" style="font-size: medium;">
            {{ $t("notification.date") }}
          </div>
          <div class="f-value">
            {{
              this.$dayjs(notification.deliveredDate).format(
                "D MMMM YYYY HH:mm:ss"
              )
            }}
          </div>
        </div>
      </v-col>
    </v-card-title>

    <v-card-title
      v-if="notification.subject"
      style="  padding-bottom: 0px; padding-top: 0px; "
    >
      <v-col cols="12" md="12" class="px-0 pb-3">
        <div class="field-wrapper">
          <div class="f-label" style="font-size: medium;">
            {{ $t("notification.subject") }}
          </div>
          <div class="f-value">{{ notification.subject }}</div>
        </div>
      </v-col>
    </v-card-title>

    <v-card-title style="  padding-bottom: 0px; padding-top: 0px; ">
      <v-col cols="12" md="12" class="px-0 pb-3">
        <div class="field-wrapper">
          <div class="f-label" style="font-size: medium;">
            {{ $t("notification.message") }}
          </div>
          <div class="f-value">{{ notification.body }}</div>
        </div>
      </v-col>
    </v-card-title>

    <v-card-actions>
      <v-spacer />
      <v-btn
        v-if="
          notification.payload &&
            JSON.parse(notification.payload) &&
            JSON.parse(notification.payload).nav_to
        "
        @click="handleLink"
        :href="JSON.parse(notification.payload).nav_to"
      >
        Dettaglio
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<style lang="scss">
.notification-card {
  .news {
    cursor: pointer;
  }
  .news-text {
    flex: 1;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .f-value {
    font-size: small;
  }
}
</style>
<script>
export default {
  name: "NotificationsCard",
  props: {
    notification: { type: Object, required: true },
    showOpened: { type: Boolean, default: true },
    showRead: { type: Boolean, default: false }
  },
  methods: {
    handleLink() {
      this.$emit("read");
      let link = new URL(this.notification.navTo, window.location.href);
      //utilizzare stessa tecnica della page per capire se è un link interno o no....
      if (global.config.domainWhitelist.includes(link.hostname)) {
        let resolved = this.$router.resolve({
          path: link.pathname
        });
        if (
          resolved &&
          resolved.route.matched.length > 0 &&
          resolved.route.name != "DefaultRoute"
        ) {
          this.$router.push(resolved.route);
        } else {
          openExternal(link);
        }
      } else {
        openExternal(link);
      }
      function openExternal(link) {
        if (this.isCordova && link.indexOf("http") > -1) {
          //if app open on _system browser
          window.cordova.InAppBrowser.open(link.href, "_system");
        } else {
          window.open(link, "blank");
        }
      }
    }
  }
};
</script>
